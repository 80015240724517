import { Get } from "../http/httpVerbs";

export const apiGetRankingPeriodos = async (params) => {
    try {
        const queryString = params ? `?${new URLSearchParams(params).toString()}` : "";
        const response = await Get(`${process.env.REACT_APP_API_URL}/rankingPeriodos${queryString}`);
        return response;
    } catch (error) {
        throw error;
    }
};
