import { Put } from "../http/httpVerbs";

export const apiUpdatePost = async (postId, data) => {
    try {
        const response = await Put(`${process.env.REACT_APP_API_URL}/posts/${postId}`, data);
        return response;
    } catch (error) {
        throw error;
    }
};
