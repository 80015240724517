import React from "react";
import { Box } from "@mui/material";

export const Gamification = () => {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                backgroundColor: "var(--grey-100-color)",
            }}
        >
            {/* Renderizando o PDF dentro de um iframe */}
            <iframe
                src="https://drive.google.com/file/d/1AHX6tinA-HOLzunNIWSuHJbA-YL9a527/preview"
                width="100%"
                height="100%"
                style={{
                    border: "none",
                    backgroundColor: "var(--grey-100-color)",
                }}
                title="Gamification PDF"
            ></iframe>
        </Box>
    );
};
