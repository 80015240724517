import { styled } from "@mui/system";
import { Box, Typography, Paper } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
    backgroundColor: "var(--grey-50-color)",
    padding: theme.spacing(2),
    borderRadius: 10,
}));

export const Title = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(1),
    fontSize: "1.1rem",
    letterSpacing: "0.05em",
    textTransform: "uppercase",
    fontStyle: "italic",
    color: "var(--primary-dark-color)",
}));

export const ScrollContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    overflowX: "auto",
    scrollBehavior: "smooth",
    gap: "12px",
    padding: "8px 0",
    "&::-webkit-scrollbar": {
        height: "6px",
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        borderRadius: "10px",
    },
    [theme.breakpoints.up("md")]: {
        overflowX: "scroll",
    },
}));

export const OpportunityItem = styled(Paper)(({ theme, isWizard }) => ({
    width: "270px",
    height: "120px",
    minHeight: "120px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-end",
    padding: theme.spacing(2),
    borderRadius: 10,
    backgroundColor: "var(--grey-300-color)",
    border: `1px solid var(--grey-400-color)`,
    boxShadow: "none",
    overflow: "hidden",
    cursor: "pointer",
    transition: "transform 0.2s ease-in-out",
    "&:hover": {
        transform: "scale(1.05)",
    },
    [theme.breakpoints.down("xs")]: {
        width: isWizard ? "300px" : "180px",
    },
}));

export const BoldText = styled(Typography)(({ theme, isWizard }) => ({
    fontWeight: "bold",
    fontSize: "1.3rem",
    color: isWizard ? "var(--white-color)" : "var(--primary-color)",
    marginBottom: theme.spacing(0.5),
    textAlign: isWizard ? "right" : "left",
    textTransform: isWizard ? "uppercase" : "none",
    fontStyle: isWizard ? "italic" : "normal",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    lineHeight: "1.2",
    height: "calc(1.2em * 2)"
}));

export const Subtitle = styled(Typography)(({ theme, isWizard }) => ({
    fontSize: isWizard ? "1.3rem" : "1.1rem",
    color: isWizard ? "var(--engagement-color)" : "var(--grey-800-color)",
    marginBottom: theme.spacing(1),
    margin: isWizard ? "8px 0" : "10px 0px",
    textAlign: isWizard ? "right" : "left",
}));
