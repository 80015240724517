import React, { useState, useEffect } from "react";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    MenuItem,
    Grid,
    Chip,
    IconButton,
    Menu,
    ListItemIcon,
    ListItemText,
    useTheme,
    useMediaQuery,
    Select,
    Checkbox,
    ListItemText as MuiListItemText,
    FormControl,
    InputLabel,
    Card,
    Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PushPinIcon from "@mui/icons-material/PushPin";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import { apiGetPosts } from "../../../services/apiGetPosts";
import { apiApprovePost, apiRejectPost, apiDeletePost } from "../../../services/apiPostStatus";
import { apiUpdatePost } from "../../../services/apiUpdatePost";
import { useParams } from "react-router-dom";
import { Loader } from "../../../components/ui/Loader";
import { POST_STATUS } from "../../../data/Interfaces";

const editoriasList = ["Dicas", "Culinária", "Tecnologia", "Educação"];
const statusList = POST_STATUS.map((status) => status.label);

const getStatusChipColor = (status) => {
    switch (status) {
        case "APROVADO":
            return { bg: "#E8F5E9", color: "#2E7D32" };
        case "REPROVADO":
            return { bg: "#FFEBEE", color: "#C62828" };
        case "PENDENTE":
            return { bg: "#FFF3E0", color: "#EF6C00" };
        default:
            return { bg: "#E0E0E0", color: "#616161" };
    }
};

const getStatusLabel = (statusValue) => {
    const status = POST_STATUS.find((s) => s.value === statusValue);
    return status ? status.label : POST_STATUS.find((s) => s.value === "PENDENTE").label;
};

const getStatusValue = (label) => {
    const status = POST_STATUS.find((s) => s.label === label);
    return status ? status.value : "PENDENTE";
};

const MENU_ITEMS = {
    approve: {
        action: "approve",
        icon: <CheckCircleIcon fontSize="small" />,
        text: "Aprovar",
    },
    reject: {
        action: "reject",
        icon: <CancelIcon fontSize="small" />,
        text: "Reprovar",
    },
    delete: {
        action: "delete",
        icon: <DeleteIcon fontSize="small" />,
        text: "Excluir",
    },
    pin: {
        action: "pin",
        icon: <PushPinOutlinedIcon fontSize="small" />,
        text: "Pinar",
    },
    unpin: {
        action: "unpin",
        icon: <PushPinIcon fontSize="small" />,
        text: "Despinar",
    },
};

const getAvailableActions = (status, pinado) => {
    const baseActions = [];
    
    switch (status) {
        case "PENDENTE":
            baseActions.push("approve", "reject", "delete");
            break;
        case "APROVADO":
            baseActions.push("reject", "delete");
            break;
        case "REPROVADO":
            baseActions.push("approve", "delete");
            break;
        default:
            baseActions.push("delete");
    }

    baseActions.push(pinado ? "unpin" : "pin");
    return baseActions;
};

export const PostsReport = ({ title = "Filtrar Posts", defaultStatus = [] }) => {
    const { id: communityId } = useParams();
    const [allPosts, setAllPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
    const [filterAuthors, setFilterAuthors] = useState([]);
    const [filterEditorias, setFilterEditorias] = useState([]);
    const [filterStatus, setFilterStatus] = useState(defaultStatus);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedPost, setSelectedPost] = useState(null);
    const [isUpdated, setIsUpdated] = useState(false);

    useEffect(() => {
        setFilterStatus(defaultStatus);
    }, [defaultStatus]);

    const fetchPosts = async () => {
        try {
            setLoading(true);
            setIsUpdated(false);
            const response = await apiGetPosts(1, communityId);
            setAllPosts(Array.isArray(response) ? response : []);
            setIsUpdated(true);
        } catch (error) {
            console.error("Erro ao buscar posts:", error);
            setAllPosts([]);
            setIsUpdated(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPosts();
    }, [communityId]);

    const handleAuthorChange = (event) => {
        const value = event.target.value;
        setFilterAuthors(value.includes("todos") ? [] : value.filter((v) => v !== "todos"));
    };

    const handleEditoriaChange = (event) => {
        const value = event.target.value;
        setFilterEditorias(value.includes("todos") ? [] : value.filter((v) => v !== "todos"));
    };

    const handleStatusChange = (event) => {
        const value = event.target.value;
        setFilterStatus(value.includes("todos") ? [] : value.filter((v) => v !== "todos"));
    };

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };

    const handleSort = (key) => {
        let direction = "asc";
        if (sortConfig.key === key && sortConfig.direction === "asc") {
            direction = "desc";
        }
        setSortConfig({ key, direction });
    };

    const handleMenuOpen = (event, post) => {
        setAnchorEl(event.currentTarget);
        setSelectedPost(post);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedPost(null);
    };

    const handleAction = async (action) => {
        if (!selectedPost) return;

        try {
            setIsUpdated(false);
            switch (action) {
                case "approve":
                    await apiApprovePost(selectedPost.id);
                    break;
                case "reject":
                    await apiRejectPost(selectedPost.id);
                    break;
                case "delete":
                    await apiDeletePost(selectedPost.id);
                    break;
                case "pin":
                    await apiUpdatePost(selectedPost.id, { pinado: true });
                    break;
                case "unpin":
                    await apiUpdatePost(selectedPost.id, { pinado: false });
                    break;
            }
            await fetchPosts();
        } catch (error) {
            console.error("Error:", error);
        } finally {
            handleMenuClose();
        }
    };

    const uniqueAuthors = React.useMemo(() => {
        const authors = allPosts
            .filter((post) => post?.creator?.nome)
            .map((post) => post.creator.nome)
            .filter((v, i, a) => a.indexOf(v) === i)
            .sort();
        return ["todos", ...authors];
    }, [allPosts]);

    const uniqueCategorias = React.useMemo(() => {
        const categorias = allPosts
            .reduce((acc, post) => {
                if (post?.categorias && Array.isArray(post.categorias)) {
                    acc.push(...post.categorias);
                }
                return acc;
            }, [])
            .filter((v, i, a) => a.indexOf(v) === i)
            .sort();
        return ["todos", ...categorias];
    }, [allPosts]);

    const filteredPosts = React.useMemo(() => {
        return allPosts.filter((post) => {
            const authorMatch = filterAuthors.length === 0 || filterAuthors.includes(post?.creator?.nome);
            const editoriaMatch =
                filterEditorias.length === 0 || post?.categorias?.some((cat) => filterEditorias.includes(cat));
            const statusMatch = filterStatus.length === 0 || post?.status === getStatusValue(filterStatus[0]);

            const normalizeDate = (date) => {
                if (!date) return null;
                const d = new Date(date);
                return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, "0")}-${String(d.getDate()).padStart(2, "0")}`;
            };

            const postDate = normalizeDate(post?.createdAt);
            const startDateObj = startDate;
            const endDateObj = endDate;

            const dateMatch =
                (!startDateObj || (postDate && postDate >= startDateObj)) &&
                (!endDateObj || (postDate && postDate <= endDateObj));

            return authorMatch && editoriaMatch && statusMatch && dateMatch;
        });
    }, [allPosts, filterAuthors, filterEditorias, filterStatus, startDate, endDate]);

    const sortedPosts = React.useMemo(() => {
        if (!filteredPosts || !Array.isArray(filteredPosts)) return [];

        let sortedItems = [...filteredPosts];
        if (sortConfig.key) {
            sortedItems.sort((a, b) => {
                if (!a || !b) return 0;

                let valA = a[sortConfig.key];
                let valB = b[sortConfig.key];

                if (sortConfig.key === "creator.nome") {
                    valA = a?.creator?.nome;
                    valB = b?.creator?.nome;
                }

                if (valA < valB) {
                    return sortConfig.direction === "asc" ? -1 : 1;
                }
                if (valA > valB) {
                    return sortConfig.direction === "asc" ? 1 : -1;
                }
                return 0;
            });
        }
        return sortedItems;
    }, [filteredPosts, sortConfig]);

    if (!isUpdated) {
        return <Loader />;
    }

    return (
        <Box>
            <Card sx={{ p: 3, mb: 3, mt: 2 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                    {title}
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl fullWidth>
                            <InputLabel>Autor</InputLabel>
                            <Select
                                multiple
                                value={filterAuthors}
                                onChange={handleAuthorChange}
                                renderValue={(selected) => selected.join(", ")}
                            >
                                {uniqueAuthors.map((author) => (
                                    <MenuItem key={author} value={author}>
                                        <Checkbox
                                            checked={
                                                author === "todos"
                                                    ? filterAuthors.length === 0
                                                    : filterAuthors.indexOf(author) > -1
                                            }
                                        />
                                        <MuiListItemText primary={author === "todos" ? "Todos os autores" : author} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl fullWidth>
                            <InputLabel>Editoria</InputLabel>
                            <Select
                                multiple
                                value={filterEditorias}
                                onChange={handleEditoriaChange}
                                renderValue={(selected) => selected.join(", ")}
                            >
                                <MenuItem key="todos" value="todos">
                                    <Checkbox checked={filterEditorias.length === 0} />
                                    <MuiListItemText primary="Todas as editorias" />
                                </MenuItem>
                                {uniqueCategorias
                                    .filter((cat) => cat !== "todos")
                                    .map((categoria) => (
                                        <MenuItem key={categoria} value={categoria}>
                                            <Checkbox checked={filterEditorias.indexOf(categoria) > -1} />
                                            <MuiListItemText primary={categoria} />
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <FormControl fullWidth>
                            <InputLabel>Status</InputLabel>
                            <Select
                                multiple
                                value={filterStatus}
                                onChange={handleStatusChange}
                                renderValue={(selected) => selected.join(", ")}
                            >
                                <MenuItem key="todos" value="todos">
                                    <Checkbox checked={filterStatus.length === 0} />
                                    <MuiListItemText primary="Todos os status" />
                                </MenuItem>
                                {statusList.map((status) => (
                                    <MenuItem key={status} value={status}>
                                        <Checkbox checked={filterStatus.indexOf(status) > -1} />
                                        <MuiListItemText primary={status} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <TextField
                            fullWidth
                            type="date"
                            label="Data Início"
                            value={startDate}
                            onChange={handleStartDateChange}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <TextField
                            fullWidth
                            type="date"
                            label="Data Fim"
                            value={endDate}
                            onChange={handleEndDateChange}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                </Grid>
            </Card>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Cover</TableCell>
                            <TableCell onClick={() => handleSort("texto")} style={{ cursor: "pointer" }}>
                                Texto
                            </TableCell>
                            <TableCell onClick={() => handleSort("creator.nome")} style={{ cursor: "pointer" }}>
                                Autor
                            </TableCell>
                            <TableCell onClick={() => handleSort("createdAt")} style={{ cursor: "pointer" }}>
                                Data
                            </TableCell>
                            <TableCell>Comentários</TableCell>
                            <TableCell>Likes</TableCell>
                            <TableCell>Editorias</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedPosts.map((post) => (
                            <TableRow key={post?.id}>
                                <TableCell>
                                    {(post?.media?.imageUrl || post?.media?.videoCapa) && (
                                        <img
                                            src={post?.media?.imageUrl || post?.media?.videoCapa}
                                            alt=""
                                            style={{ width: 45, height: 80, objectFit: "cover" }}
                                        />
                                    )}
                                </TableCell>
                                <TableCell>
                                    {post?.pinado && <PushPinIcon fontSize="small" sx={{ marginRight: 1, color: 'primary.main' }} />}
                                    {post?.texto}
                                </TableCell>
                                <TableCell>{post?.creator?.nome}</TableCell>
                                <TableCell>
                                    {post?.createdAt && new Date(post.createdAt).toLocaleDateString()}
                                </TableCell>
                                <TableCell>{post?.engajamento?.comentarios?.length || 0}</TableCell>
                                <TableCell>{post?.engajamento?.likes?.length || 0}</TableCell>
                                <TableCell>
                                    {(post?.categorias || []).map((categoria, index) => (
                                        <Chip key={index} label={categoria} size="small" sx={{ mr: 0.5, mb: 0.5 }} />
                                    ))}
                                </TableCell>
                                <TableCell>
                                    <Chip
                                        label={getStatusLabel(post?.status)}
                                        size="small"
                                        sx={{
                                            backgroundColor: getStatusChipColor(post?.status || "PENDENTE").bg,
                                            color: getStatusChipColor(post?.status || "PENDENTE").color,
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <IconButton onClick={(e) => handleMenuOpen(e, post)}>
                                        <MoreVertIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                {getAvailableActions(selectedPost?.status, selectedPost?.pinado).map((actionKey) => {
                    const menuItem = MENU_ITEMS[actionKey];
                    return (
                        <MenuItem key={actionKey} onClick={() => handleAction(menuItem.action)}>
                            <ListItemIcon>{menuItem.icon}</ListItemIcon>
                            <ListItemText>{menuItem.text}</ListItemText>
                        </MenuItem>
                    );
                })}
            </Menu>
        </Box>
    );
};
