import React, { useState, useEffect } from "react";
import { Box, Drawer, Button, Typography, Container } from "@mui/material";
import * as S from "./styles";
import { Opportunity } from "../../pages/opportunities/opportunity";
import { returnEnvironment } from "../../utils";
import { useLocation } from "react-router-dom";
import { useAuthStore } from "../../store/useAuthStore";
import { apiPostOportunidadeCreator } from "../../services/apiPostOportunidadeCreator";

const OpportunityList = ({ title, opportunities, isWizard, onSelect }) => (
    <Container maxWidth="md" disableGutters sx={{ marginBottom: 4 }}>
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
            {title}
        </Typography>
        {opportunities?.length === 0 ? (
            <Typography variant="body2">Nenhuma oportunidade encontrada.</Typography>
        ) : (
            opportunities?.map((opportunity, index) => (
                <S.OpportunityItem
                    key={index}
                    isWizard={isWizard}
                    onClick={() => onSelect(opportunity)}
                    className="opportunityCard"
                >
                    <Box>
                        <S.BoldText isWizard={isWizard}>{truncateDescription(opportunity.titulo, true)}</S.BoldText>
                        <S.Subtitle isWizard={isWizard}>{`+${opportunity.recompensa_valor} pontos`}</S.Subtitle>
                    </Box>
                    <Button variant="contained" className="opportunity-btn">
                        Saiba mais
                    </Button>
                </S.OpportunityItem>
            ))
        )}
    </Container>
);

const truncateDescription = (text, isTitle) => {
    const maxLength = isTitle ? 22 : 50;
    if (text?.length <= maxLength) return text;
    return `${text.substring(0, maxLength - 3)}...`;
};

export const AllOpportunities = () => {
    const [selectedOpportunity, setSelectedOpportunity] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const { state } = useLocation();
    const { opportunities } = state || [];
    const creator = useAuthStore((state) => state.creator);

    const isWizard = returnEnvironment() === "wizard";
    const [opportunitiesFiltered, setOpportunitiesFiltered] = useState(opportunities);

    const handleOpenOpportunity = (opportunity) => {
        setSelectedOpportunity(opportunity);
        setDrawerOpen(true);
    };

    const handleCloseOpportunity = () => {
        setDrawerOpen(false);
        setSelectedOpportunity(null);
    };

    const availableOpportunities = opportunitiesFiltered?.filter((opportunity) => !opportunity.applied);
    const appliedOpportunities = opportunitiesFiltered?.filter((opportunity) => opportunity.applied);

    const fetchOpportunities = async () => {
        try {
            const opportunitiesChanged = await Promise.all(
                opportunities?.map(async (opportunity) => {
                    const payload = { oportunidade: opportunity.id, creator: creator.id };
                    const data = await apiPostOportunidadeCreator(payload);
                    const opportunityData = data.oportunidade === opportunity.id;

                    return {
                        ...opportunity,
                        applied: opportunityData,
                    };
                }),
            );

            setOpportunitiesFiltered(opportunitiesChanged);
        } catch (error) {
            console.error("Error updating opportunities:", error);
        }
    };

    useEffect(() => {
        fetchOpportunities();
    }, []);

    return (
        <>
            <S.Container>
                <S.Title variant="h5">Oportunidades</S.Title>
                <S.ScrollContainer>
                    <OpportunityList
                        title="Oportunidades Disponíveis"
                        opportunities={availableOpportunities}
                        isWizard={isWizard}
                        onSelect={handleOpenOpportunity}
                    />
                    <OpportunityList
                        title="Minhas Aplicações"
                        opportunities={appliedOpportunities}
                        isWizard={isWizard}
                        onSelect={handleOpenOpportunity}
                    />
                </S.ScrollContainer>
            </S.Container>

            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={handleCloseOpportunity}
                sx={{
                    "& .MuiDrawer-paper": {
                        padding: 2,
                        width: "100%",
                        maxWidth: "600px",
                        height: "100%",
                        backgroundColor: "var(--white-color)",
                        overflowY: "auto",
                    },
                }}
            >
                {selectedOpportunity && (
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Opportunity opportunity={selectedOpportunity} closeModal={handleCloseOpportunity} />
                    </Box>
                )}
            </Drawer>
        </>
    );
};
