import React from "react";
import { Box, Typography } from "@mui/material";
import { ManageNavigation } from "./components/ManageNavigation";
import { PostsReport } from "./components/PostsReport";
import { MembersReport } from "./components/MembersReport";
import { Routes, Route, Navigate } from "react-router-dom";
import { NoAccess } from "../../components/ui/NoAccess";
import { creatorIsAdmin } from "../../utils/adminManager";

export const ManageCommunity = () => {
    if (!creatorIsAdmin()) {
        return <NoAccess />;
    }

    return (
        <Box sx={{ px: 2, mt: 3 }}>
            <Typography
                variant="h4"
                sx={{
                    my: 2,
                    fontWeight: "bold",
                    color: "var(--grey-800-color)",
                    letterSpacing: 0.5,
                }}
            >
                Gerenciar Comunidade
            </Typography>

            <ManageNavigation />

            <Routes>
                <Route path="/" element={<Navigate to="posts" replace />} />
                <Route path="/posts" element={<PostsReport title="Relatório de Posts" defaultStatus={[]} />} />
                <Route path="/membros" element={<MembersReport />} />
                <Route
                    path="/moderacao"
                    element={<PostsReport title="Moderação de Conteúdos" defaultStatus={["Pendente"]} />}
                />
            </Routes>
        </Box>
    );
};
