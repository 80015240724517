import { Get } from "../http/httpVerbs";

export const apiGetPosts = async (page = 1, campanha, filters = {}) => {
    try {
        let url = `${process.env.REACT_APP_API_URL}/posts?page=${page}`;

        if (campanha) {
            url += `&campanha=${campanha}`;
        }

        if (filters.author) {
            url += `&author=${filters.author}`;
        }

        if (filters.editoria) {
            url += `&editoria=${filters.editoria}`;
        }

        if (filters.status) {
            url += `&status=${filters.status}`;
        }

        if (filters.dataInicio) {
            url += `&dataInicio=${filters.dataInicio}`;
        }

        if (filters.dataFim) {
            url += `&dataFim=${filters.dataFim}`;
        }

        if (filters.iLiked) {
            url += `&iLiked=${filters.iLiked}`;
        }

        if (filters.categories && filters.categories.length > 0) {
            url += `&categories=${filters.categories.join(",")}`;
        }

        if (filters.type) {
            url += `&type=${filters.type}`;
        }

        const response = await Get(url);

        return response;
    } catch (error) {
        console.error("API apiGetPosts ERROR:", error);
        throw error;
    }
};
