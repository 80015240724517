import { styled } from "@mui/material/styles";
import { Box, Typography, Paper } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    backgroundColor: "var(--background-color)",
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    height: "100vh",
}));

export const Title = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    color: "var(--primary-color)",
    fontWeight: 600,
}));

export const ScrollContainer = styled(Box)(({ theme }) => ({
    overflowY: "auto",
    maxHeight: "70vh",
    paddingRight: theme.spacing(1),
}));

export const OpportunityItem = styled(Paper)(({ theme, isWizard }) => ({
    height: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: isWizard ? "flex-end" : "flex-start",
    alignItems: isWizard ? "flex-end" : "flex-start",
    padding: isWizard ? theme.spacing(2, 2) : theme.spacing(2),
    borderRadius: 10,
    backgroundColor: "var(--grey-300-color)",
    border: `1px solid var(--grey-400-color)`,
    boxShadow: "none",
    overflow: "hidden",
    cursor: "pointer",
    transition: "transform 0.2s ease-in-out",
    marginBottom: theme.spacing(2),
    "&:hover": {
        transform: "scale(1.05)",
    },

    [theme.breakpoints.down("xs")]: {
        width: "100%",
    },
}));

export const BoldText = styled(Typography)(({ theme, isWizard }) => ({
    fontWeight: "bold",
    fontSize: "1.3rem",
    color: isWizard ? "var(--white-color)" : "var(--primary-color)",
    marginBottom: theme.spacing(0.5),
    textAlign: isWizard ? "right" : "left",
    textTransform: isWizard ? "uppercase" : "none",
    fontStyle: isWizard ? "italic" : "normal",
}));

export const Subtitle = styled(Typography)(({ theme, isWizard }) => ({
    fontSize: isWizard ? "1.3rem" : "1.1rem",
    color: isWizard ? "var(--engagement-color)" : "var(--grey-800-color)",
    marginBottom: theme.spacing(1),
    margin: isWizard ? "8px 0" : "10px 0px",
    textAlign: isWizard ? "right" : "left",
}));
