import React, { useState, useEffect } from "react";
import { Box, Container } from "@mui/material";
import { useMobileDetect } from "../../hooks";
import "./dashboard.css";
import { Header } from "../../components/ui/Header";
import { Loader } from "../../components/ui/Loader";
import { useAuthStore } from "../../store/useAuthStore";
import { apiGetCreators } from "../../services/apiGetCreators";
import { returnEnvironment } from "../../utils";
import { TermsAcceptanceModal } from "../../components/ui/TermsAcceptanceModal";
import { Patch } from "../../http/httpVerbs";
import { creatorIsAdmin } from "../../utils/adminManager";
import { Menu } from "../../components/ui/Menu";
const environment = returnEnvironment();

export const Dashboard = ({ children }) => {
    const creator = useAuthStore((state) => state.creator);
    const updateCreator = useAuthStore((state) => state.updateCreator);
    const isMobile = useMobileDetect();
    const isAdmin = creatorIsAdmin();

    const [LoginComponent, setLoginComponent] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [showTerms, setShowTerms] = useState(false);

    useEffect(() => {
        const loadLoginComponent = async () => {
            const environment = returnEnvironment();
            try {
                const module = await import(`../login/${environment}`);
                setLoginComponent(() => module.Login);
            } catch (error) {
                console.error(`Erro ao carregar o módulo Login para o ambiente ${environment}:`, error);
            }
        };

        loadLoginComponent();
    }, []);

    useEffect(() => {
        const checkHashAndFetchCreator = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const hash = urlParams.get("hash");

            if (hash) {
                try {
                    const decodedData = atob(hash);
                    const [email, validade] = decodedData.split("|");
                    const agora = new Date();

                    if (new Date(validade) > agora) {
                        const response = await apiGetCreators(email);
                        const creatorData = response[0];

                        if (creatorData) {
                            updateCreator(creatorData);

                            urlParams.delete("hash");

                            window.location.href = "/editUserInfo";
                        }
                    }
                } catch (error) {
                    console.log({ error });
                } finally {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        };

        if (!creator) {
            checkHashAndFetchCreator();
        } else {
            setIsLoading(false);
        }
    }, [creator, updateCreator]);

    useEffect(() => {
        if (creator && environment === "wizard" && !creator.termos) {
            setShowTerms(true);
        }
    }, [creator]);

    const handleAcceptTerms = async () => {
        try {
            setIsLoading(true);
            const url = `${process.env.REACT_APP_API_URL}/creators/${creator.id}`;

            await Patch(url, { termos: true });

            const updatedCreator = {
                ...creator,
                termos: true,
            };

            updateCreator(updatedCreator);
            setShowTerms(false);
        } catch (error) {
            console.error("Erro ao atualizar termos:", error);
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading || !LoginComponent) {
        return <Loader />;
    }

    if (!creator) {
        const Login = LoginComponent;
        return <Login />;
    }

    return (
        <Box
            sx={{
                display: "flex",
                minHeight: "100vh",
                backgroundColor: "var(--grey-100-color)",
            }}
        >
            <Box
                sx={{
                    width: isMobile ? 0 : "240px",
                    flexShrink: 0,

                    zIndex: 2,
                }}
            >
                <Menu client={environment} creatorIsAdmin={isAdmin} />
            </Box>

            <Box
                component="main"
                sx={{
                    flex: 1,
                    overflow: "auto",
                    padding: "16px",
                }}
            >
                {children}
            </Box>

            <TermsAcceptanceModal open={showTerms} onAccept={handleAcceptTerms} />
            {isLoading && <Loader />}
        </Box>
    );
};
