import { Get } from "../http/httpVerbs";

export const getImageAuthorization = async (type, creatorId, notification = false) => {
    try {
        const response = await Get(
            `${process.env.REACT_APP_API_URL}/clicksign/documents/creator/${creatorId}/${type}?notification=${notification}`,
        );
        return response;
    } catch (error) {
        throw error;
    }
};
