import { create } from "zustand";
import { apiLogin } from "../services/apiLogin";
import { getUserKey } from "../http/httpVerbs";
import { setAdminStatus, clearAdminStatus } from "../utils/adminManager";

export const useAuthStore = create((set, get) => ({
    creator: null,

    fetchCreator: () => {
        const storedCreator = localStorage.getItem(getUserKey());

        if (storedCreator) {
            set({ creator: JSON.parse(storedCreator) });
        }
    },

    login: async (username, password) => {
        try {
            if (!username) throw new Error("E-mail é obrigatório");
            if (!password) throw new Error("Senha é obrigatório");

            const data = await apiLogin(username, password);

            set({ creator: data.creator });

            localStorage.setItem(getUserKey(), JSON.stringify(data.creator));

            setAdminStatus(data.creator);
        } catch (error) {
            throw error;
        }
    },

    logout: () => {
        set({ creator: null });

        localStorage.removeItem(getUserKey());

        clearAdminStatus();
    },

    updateCreator: (newCreatorData) => {
        set({ creator: newCreatorData });

        localStorage.setItem(getUserKey(), JSON.stringify(newCreatorData));

        setAdminStatus(newCreatorData);
    },
}));
