const ADMIN_STATUS_KEY = "creator_admin_status";
const ADMIN_CLIENT_KEY = "creator_admin_client";

export const setAdminStatus = (creator) => {
    if (!creator) {
        clearAdminStatus();
        return;
    }

    const isAdmin = creator.admin_privilegios || false;
    const adminClientIds = creator.admin_clientes ? creator.admin_clientes.split(",") : [];
    const currentAdminClient = adminClientIds.length > 0 ? adminClientIds[0] : null;

    localStorage.setItem(ADMIN_STATUS_KEY, JSON.stringify(isAdmin));
    localStorage.setItem(ADMIN_CLIENT_KEY, JSON.stringify(currentAdminClient));
};

export const clearAdminStatus = () => {
    localStorage.removeItem(ADMIN_STATUS_KEY);
    localStorage.removeItem(ADMIN_CLIENT_KEY);
};

export const creatorIsAdmin = () => {
    const adminStatus = localStorage.getItem(ADMIN_STATUS_KEY);
    return adminStatus ? JSON.parse(adminStatus) : false;
};

export const getCurrentAdminClient = () => {
    const adminClient = localStorage.getItem(ADMIN_CLIENT_KEY);
    return adminClient ? JSON.parse(adminClient) : null;
};
