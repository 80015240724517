import React, { useState } from 'react';
import {
    Box,
    Modal,
    Typography,
    FormControlLabel,
    Checkbox,
    Button,
} from '@mui/material';
import { ButtonFL } from '../ButtonFL';

export const TermsAcceptanceModal = ({ open, onAccept }) => {
    const [accepted, setAccepted] = useState(false);

    const handleAccept = () => {
        if (accepted) {
            onAccept();
        }
    };

    return (
        <Modal 
            open={open} 
            aria-labelledby="terms-modal-title"
            aria-describedby="terms-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: {
                        xs: '85%',
                        sm: '80%',
                        md: '70%'
                    },
                    maxWidth: 800,
                    maxHeight: {
                        xs: '80vh',
                        sm: '85vh',
                        md: '90vh'
                    },
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: {
                        xs: 2,
                        sm: 3,
                        md: 4
                    },
                    borderRadius: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    overflowY: 'auto'
                }}
            >
                <Typography id="terms-modal-title" variant="h5" component="h2" sx={{ 
                    mb: 3,
                    fontSize: {
                        xs: '1.2rem',
                        sm: '1.4rem',
                        md: '1.5rem'
                    }
                }}>
                    TERMOS E CONDIÇÕES GERAIS DE USO DA PLATAFORMA WIZARD
                </Typography>
                
                <Box sx={{ 
                    flex: 1, 
                    overflowY: 'scroll', 
                    mb: 2, 
                    height: {
                        xs: '300px',
                        sm: '350px',
                        md: '400px'
                    },
                    border: '1px solid #e0e0e0', 
                    borderRadius: 1, 
                    p: {
                        xs: 1.5,
                        sm: 2
                    }
                }}>
                    <Typography variant="body1" paragraph>
                        Estes Termos e Condições Gerais de Uso ("Termos de Uso", "Termos" ou "Instrumento") regulamentam a utilização da Plataforma Wizard ("Plataforma", "Nós"), disponibilizado aos usuários da Plataforma, classificados como Professor, Curso e/ou Rede de Cursos ("Usuários", "Você", "Seu", "Sua"), conforme disposições a seguir.
                    </Typography>

                    <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>
                        DEFINIÇÃO DE TERMOS
                    </Typography>

                    <Typography variant="body1" paragraph>
                        <strong>Acesso:</strong> Ação do Usuário de acessar a Plataforma.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        <strong>Autores:</strong> Pessoas que produziram os conteúdos didáticos que estão disponíveis na Plataforma.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        <strong>Conteúdo:</strong> Toda e qualquer informação ou material de estudo hospedado na Plataforma.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        <strong>Curso:</strong> Instituição privada de ensino que utiliza a Plataforma para fins didáticos aos seus Usuários.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        <strong>Usuários:</strong> Professores que utilizam a Plataforma.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        <strong>Plataforma:</strong> Website que disponibiliza conteúdos educacionais em forma de videoaulas, testes, simulados e objetos educacionais digitais, além de oferecer estatísticas diversas resultantes da utilização dos conteúdos educacionais.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        <strong>Professor:</strong> Profissional que se dedica ao ensino e que é vinculado ao Curso.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        <strong>Serviços:</strong> Disponibilização de conteúdos educacionais em forma de videoaulas, testes, simulados e objetos educacionais digitais, além de estatísticas diversas resultantes da utilização dos conteúdos educacionais.
                    </Typography>

                    <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>
                        1. DESTINAÇÃO DA PLATAFORMA
                    </Typography>

                    <Typography variant="body1" paragraph>
                        1.1. A Plataforma será um espaço virtual para que os Usuários possam compartilhar suas produções de conteúdo em formato de videoaulas, de forma a criar uma biblioteca virtual de conteúdos compartilhados.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        1.2. A WIZARD disponibilizará na Plataforma conteúdos educacionais, no formato de videoaulas, testes, simulados e objetos educacionais digitais, além de oferecer estatísticas diversas resultantes da utilização dos conteúdos educacionais.
                    </Typography>

                    <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>
                        2. CONDIÇÕES DE USO DA PLATAFORMA E VIGÊNCIA
                    </Typography>

                    <Typography variant="body1" paragraph>
                        2.1. O Seu uso dos Serviços e da Plataforma é regido por estes Termos de Uso, os quais Você deve ler atentamente antes de utilizar os nossos Serviços e Plataforma.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        2.2. Registrando-se, acessando e utilizando a Plataforma de qualquer forma, incluindo cadastro, navegação, visualização, download, geração, recebimento e transmissão de quaisquer dados e informações, Você manifesta Sua expressa concordância, em Seu nome e/ou em nome do Seu empregador para com estes Termos de Uso.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        2.2.1. No uso dos Serviços, Você se compromete a respeitar e cumprir todas as disposições aqui contidas.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        2.2.2. Você está ciente e de acordo que o Conteúdo postado por Você na Plataforma poderá ser utilizado, baixado, reproduzido pelos demais Usuários para fins diversos, desde que de acordo com estes Termos.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        2.3. Estes Termos constituem um contrato vinculante entre a WIZARD e Você e foram elaborados para possibilitar a Sua utilização da Plataforma. Se Você não concorda com estes Termos, não utilize a Plataforma ou os Serviços aqui indicados.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        2.4. A WIZARD poderá atualizar ou alterar estes Termos a qualquer tempo. Todas as alterações realizadas serão comunicadas aos Usuários por meio de avisos na própria Plataforma e pelos endereços de e-mail utilizados para cadastro para acesso aos Serviços. Após a publicização da alteração destes Termos de Uso, o Seu uso continuado dos Serviços ou da Plataforma constitui Sua expressa concordância para com os Termos, conforme alterados.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        2.5. Os Serviços da Plataforma são, atualmente, disponibilizados de maneira gratuita, havendo alteração nesta condição, Você será comunicado por meio de aviso na Plataforma. No caso de a Plataforma passar a ser paga, a WIZARD se reserva o direito de, mediante atraso e/ou falta de pagamento do Usuário, suspender o oferecimento dos serviços fornecidos nesta Plataforma, desde que observado o que se segue:
                    </Typography>

                    <Typography variant="body1" paragraph sx={{ pl: 2 }}>
                        2.5.1. No caso de suspensão dos Serviços, o Usuários será previamente notificado e comunicado por meio de avisos na própria Plataforma e pelos endereços de e-mail utilizados para cadastro para acesso aos Serviços; e
                    </Typography>

                    <Typography variant="body1" paragraph sx={{ pl: 2 }}>
                        2.5.2. Ocorrendo a suspensão dos Serviços da Plataforma, as informações e os Conteúdos gerados pelo Usuário permanecerão ativos e disponíveis na Plataforma. A suspensão não implica em restrições no exercício dos direitos dos Titulares de Dados Pessoais.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        2.6. A WIZARD se reserva o direito de modificar, suprimir e/ou ampliar, livremente e a qualquer tempo, o Conteúdo e/ou configurações da Plataforma. As alterações de configuração realizadas serão comunicadas aos Usuários por meio de avisos na própria Plataforma e pelos endereços de e-mail utilizados para cadastro para acesso aos Serviços. Após a publicização da referida alteração, o Seu uso continuado dos Serviços ou da Plataforma constitui Sua expressa concordância para com as modificações realizadas.
                    </Typography>

                    <Typography variant="body1" paragraph sx={{ pl: 2 }}>
                        2.6.1. As alterações de Conteúdo, quando relevantes, poderão ser comunicadas aos Usuários por meio de avisos na própria Plataforma e pelos endereços de e-mail utilizados para cadastro para acesso aos Serviços.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        2.7. Você está ciente que o Conteúdo disponibilizado é para uso exclusivo das finalidades da WIZARD, sendo proibida a sua utilização para fins pessoais não relacionados a este Termo. O Usuário concorda, ainda, que o acesso é pessoal e intransferível, sendo o Usuário o único responsável pela segurança de seus acessos.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        2.8. O Usuário tem plena ciência de que não possui direitos de cópia, reprodução ou alteração, no todo ou em parte, de qualquer parte do Conteúdo veiculado pela Plataforma, exceto para as utilizações referentes ao Curso.
                    </Typography>

                    <Typography variant="body1" paragraph sx={{ pl: 2 }}>
                        2.8.1. O Usuário é o único responsável por qualquer disponibilização dos seus próprios Conteúdos e/ou utilização dos Conteúdos disponibilizados por terceiros, sendo ainda o único e exclusivo responsável pelos danos que vier a sofrer pela utilização indevida do Conteúdo, bem como pelos danos que vier a causar a terceiros pelos mesmos motivos, não havendo qualquer interferência ou responsabilidade da WIZARD ou da Plataforma. A WIZARD e a Plataforma se resguardam o direito de ocultar, excluir, retirar, qualquer Conteúdo que, a seu exclusivo critério, venha a ferir o presente Termo de Uso, sem prejuízo de eventual bloqueio e/ou exclusão da conta do Usuário infrator.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        2.9. Todo Conteúdo do Usuário será considerado não confidencial e não exclusivo. Você não deve publicar qualquer Conteúdo do Usuário nos Serviços, ou através dos Serviços, nem nos transmitir qualquer Conteúdo do Usuário, que você acreditar ser confidencial ou exclusivo. Ao submeter Conteúdo do Usuário através dos Serviços, Você estará aceitando e declarando que é o proprietário do Conteúdo do Usuário, ou que você obteve todas as autorizações, liberações ou permissões necessárias do titular de qualquer parte do conteúdo para submetê-lo aos Serviços, ou transmiti-lo a partir dos Serviços para plataformas de propriedade de terceiros e/ou para incorporar qualquer conteúdo de terceiros.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        2.10. O Usuário concorda em não reproduzir, duplicar, copiar, vender ou explorar com quaisquer fins comerciais qualquer porção da Plataforma, nem fazer uso da Plataforma ou do acesso para o fim de vendas de bens ou serviços, ou a promoção de uma companhia, bem ou serviço.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        2.11. A WIZARD também se reserva o direito a suspender, bloquear e/ou excluir o acesso e o cadastro dos Usuários nos casos em que (i) for detectado que o cadastro na Plataforma foi criado com informações falsas, enganosas, incorretas ou incompletas, ou que, de alguma outra forma, impeçam a correta identificação do Usuário; (ii) for detectado que o cadastro realizado e/ou a utilização dos Serviços pelo Usuário de alguma forma viola as condições e regras estabelecidas no presente Instrumento e no Aviso de Privacidade correlato.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        2.12. Salvo previsão contratual expressa em contrário, ou a liberalidade de descadastramento de Usuários por sua iniciativa própria, o vínculo com a Plataforma seguirá a temporalidade definida pela WIZARD e/ou em Acordo. Antes do descadastramento de Usuários, a WIZARD emitirá um aviso a ser direcionado aos próprios Usuários, se aplicável.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        2.13 Você ou o proprietário do Conteúdo do Usuário permanece titular dos direitos autorais no Conteúdo do Usuário que for submetido a Plataforma, porém, ao submeter o Conteúdo do Usuário através dos Serviços, Você nos outorga uma licença incondicional, irrevogável, não exclusiva, isenta de royalties, transferível, perpétua, de âmbito mundial, para usar, modificar, adaptar, reproduzir, criar obras derivadas, publicar e/ou transmitir e/ou distribuir, e autorizar outros usuários dos Serviços e terceiros a visualizar, acessar, usar, fazer download, modificar, adaptar, reproduzir, criar obras derivadas, publicar e/ou transmitir seu Conteúdo do Usuário, em qualquer formato, e em qualquer plataforma, ora conhecida ou inventada futuramente. Você nos outorga ainda uma licença isenta de royalties para usar seu nome de usuário, imagem, voz e semelhança para identificá-lo como a fonte de origem de qualquer Conteúdo do Usuário criado por você.
                    </Typography>

                    <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>
                        3. SUAS OBRIGAÇÕES NO USO DA PLATAFORMA
                    </Typography>

                    <Typography variant="body1" paragraph>
                        3.1 Utilizar a Plataforma nas condições e hipóteses autorizadas por estes Termos de Uso, respeitando ainda a legislação vigente e o direito de terceiros.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        3.2 Responsabilizar-se, integral e exclusivamente, pelos atos praticados por Você ou por terceiros autorizados por Você para acessar a Plataforma através da sua conta de Usuário.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        3.3 Tomar todas as medidas de segurança para que Você e/ou terceiros não violem nenhum direito de propriedade intelectual da WIZARD ou de terceiros, e comunicar à WIZARD, imediatamente, em caso de qualquer violação à propriedade intelectual de que venha a ter conhecimento.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        3.4 Tomar todas as medidas necessárias para que a Plataforma seja utilizada com observância destes Termos, responsabilizando-se por quaisquer violações à propriedade intelectual da WIZARD ou de qualquer terceiro praticado por si ou por Seus funcionários.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        3.5. O descumprimento de qualquer obrigação prevista nesta cláusula autoriza a WIZARD, independentemente de aviso prévio ao Usuário, a excluir, bloquear ou suspender o acesso à Plataforma, sem prejuízo das demais medidas legais cabíveis.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        3.6. O Usuário concorda em não utilizar a Plataforma para:
                    </Typography>

                    <Typography variant="body1" paragraph sx={{ pl: 2 }}>
                        3.6.1. Praticar quaisquer atos ilícitos e/ou violação da legislação vigente;
                    </Typography>

                    <Typography variant="body1" paragraph sx={{ pl: 2 }}>
                        3.6.2. Praticar atos contrários à moral e aos bons costumes;
                    </Typography>

                    <Typography variant="body1" paragraph sx={{ pl: 2 }}>
                        3.6.3. Realizar atos que causem ou propiciem a contaminação ou prejudiquem quaisquer equipamentos da WIZARD e/ou de terceiros, inclusive por meio de vírus, trojans, malware, worm, bot, backdoor, spyware, rootkit, ou por quaisquer outros dispositivos que venham a ser criados;
                    </Typography>

                    <Typography variant="body1" paragraph sx={{ pl: 2 }}>
                        3.6.4. Praticar quaisquer atos que direta ou indiretamente, no todo ou em parte, possam causar prejuízo à WIZARD, a outros Usuários e/ou a quaisquer terceiros;
                    </Typography>

                    <Typography variant="body1" paragraph>
                        3.7. O Usuário reconhece que, em qualquer hipótese, será o único responsável pelo uso que fizer da Plataforma, bem como por qualquer Conteúdo ou informação que transferir, transmitir ou disponibilizar através da Plataforma.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        3.8. A WIZARD não será, em hipótese alguma, responsabilizada pelos atos e Conteúdos disponibilizados na Plataforma pelos Usuários, limitando sua responsabilidade a Conteúdos de sua estrita titularidade.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        3.9. Você aceita defender, indenizar e isentar de responsabilidade a WIZARD, suas controladoras, subsidiárias e afiliadas, e cada um de seus respectivos diretores, conselheiros, empregados, agentes e consultores por todas e quaisquer reclamações, passivos, custos e despesas, incluindo, entre outras, despesas e honorários advocatícios, decorrentes de violação, por Você ou qualquer usuário da sua conta, destes Termos, ou resultantes do descumprimento de suas obrigações, declarações e garantias no âmbito destes Termos.
                    </Typography>

                    <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>
                        4. ALTERAÇÕES NOS SERVIÇOS OU NA PLATAFORMA
                    </Typography>

                    <Typography variant="body1" paragraph>
                        4.1. A WIZARD se reserva o direito a modificar, suspender, terminar ou descontinuar qualquer aspecto da Plataforma, a qualquer tempo, incluindo a disponibilidade de quaisquer Serviços, informações, características ou funcionalidades acessíveis por meio desta. Nós também podemos impor limitações ou restrições do Usuário às características, funcionalidades ou Serviços da Plataforma sem prévia notificação e sem que isso implique em qualquer responsabilidade à WIZARD por qualquer prejuízo que o Usuário venha a sofrer.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        4.2. Quaisquer melhorias ou adições à Plataforma ou aos Serviços estarão sujeitos a estes Termos de Uso, a não ser que Nós estabeleçamos expressamente de outra forma. Nós podemos introduzir novos conjuntos específicos de termos e condições para Serviços determinados, conforme apropriado, ou emendar termos e condições já existentes.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        4.3. A WIZARD se reserva o direito a, em qualquer tempo ou título, controlar e/ou alterar a aparência, o desenvolvimento e/ou operações da Plataforma a Nosso exclusivo critério, bem como estabelecer e modificar os procedimentos para o Seu contato Conosco, sem a necessidade de notificação prévia. Neste último caso, as alterações realizadas serão posteriormente comunicadas aos Usuários por meio de avisos na própria Plataforma, podendo ainda ser enviados aos endereços de e-mail utilizados para cadastro e acesso aos Serviços.
                    </Typography>

                    <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>
                        5. PROPRIEDADE INTELECTUAL
                    </Typography>

                    <Typography variant="body1" paragraph>
                        5.1. Todos os textos, imagens, fotos, vídeos, videoaulas, ilustrações, ícones, tecnologias, links e outros conteúdos visuais, audiovisuais ou sonoros, incluindo desenhos gráficos e os códigos-fonte da Plataforma gerados pela WIZARD, são propriedade exclusiva da WIZARD (ou de terceiros que autorizaram seu uso pela WIZARD) e são protegidos por leis e tratados nacionais e internacionais, sendo expressamente proibida a cópia, reprodução, download ou qualquer outro tipo de uso para fins diversos do Curso.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        5.2. Todas as marcas registradas, nomes comerciais e logotipos de qualquer tipo apresentados na página eletrônica são de propriedade da Plataforma (ou de terceiros que autorizaram seu uso pela Plataforma) e a utilização da página eletrônica não pode ser entendida como uma autorização para que os Usuários usem as marcas registradas, nomes comerciais e logotipos.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        5.3. As licenças para o uso da Plataforma são concedidas de forma não exclusiva, intransferível, não sublicenciável, sendo a mesma revogável a qualquer momento em relação a todo e qualquer Conteúdo fornecido a Você para o uso exclusivo no processamento de sistemas internos. A WIZARD não concede nenhuma outra licença ou autorização sobre os seus direitos de propriedade industrial e intelectual ou sobre qualquer outra propriedade ou direito relacionado com os Serviços e/ou a Plataforma. A WIZARD se reserva todo e qualquer direito sobre suas marcas registradas, direitos autorais, e outros direitos de propriedade intelectual, disponíveis na Plataforma, ficando vedado sua utilização sem a Nossa expressa autorização, sob pena de configuração de ilícitos civis e criminais.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        5.4. Respeitamos direitos de propriedade intelectual e esperamos o mesmo de Você. Como condição para o Seu acesso e uso dos Serviços, você aceita não utilizar os Serviços para infringir quaisquer direitos de propriedade intelectual. Reservamo-nos o direito, com ou sem prévio aviso, a qualquer tempo e ao nosso absoluto critério, de bloquear o acesso de qualquer Usuário e/ou encerrar as contas de qualquer Usuário que infringir ou supostamente infringir quaisquer direitos autorais ou outros direitos de propriedade intelectual.
                    </Typography>

                    <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>
                        6. ISENÇÃO DE RESPONSABILIDADE DA PLATAFORMA
                    </Typography>

                    <Typography variant="body1" paragraph>
                        6.1. A WIZARD não se responsabiliza: (i) por erros ou inconsistências na transmissão de dados ou pela qualidade ou disponibilidade de sinal de Internet, que possam afetar o uso da Plataforma; (ii) por quaisquer danos e/ou prejuízos decorrentes do conhecimento não autorizado, por terceiros, de qualquer informação fornecida por meio da Plataforma, e que não sejam causados por uma falha de segurança da informação da WIZARD e (iii) pelo cadastro irregular, por parte dos Usuários ou de Professores, sendo entendido por irregular a ausência de Base Legal para tratamento de dados pessoais.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        6.2. Os Usuários estão cientes de que a WIZARD recomenda o uso de ferramentas e de tecnologia (hardware e/ou software) devidamente seguras e atualizadas. Qualquer uso de ferramentas e tecnologias desatualizadas e não consideradas seguras ocorrerá por conta e risco do Usuário, e a Plataforma se eximirá de qualquer responsabilidade por quaisquer danos sofridos por estes.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        6.3. Observados os parâmetros legais aplicáveis, a WIZARD não será responsável por quaisquer danos indiretos, de qualquer natureza, que possam, ser atribuíveis ao uso ou à incapacidade de uso da Plataforma, qualquer conteúdo publicado na Plataforma, ou destes Termos de Uso, dos Serviços ou quaisquer informações, dados ou software prestado através da página eletrônica da WIZARD, mesmo se advertido sobre a possibilidade de tais danos ou no caso de tais danos serem previsíveis.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        6.4. Observados os parâmetros legais aplicáveis, a WIZARD não poderá ser responsabilizada por quaisquer danos morais e materiais diretos, indiretos, incidentais, especiais, consequenciais ou punitivos que não tenham sido causados direta e imediatamente pela Plataforma ou por seus funcionários, incluindo, mas não se limitando a perdas e danos, lucros cessantes, perda de uma chance, outras perdas e danos intangíveis, relacionados ao uso da Plataforma, nem com relação à incapacidade e/ou impossibilidade de usá-los.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        6.5. Não apresentaremos qualquer declaração, garantia ou promessa, tácita ou expressa, de que qualquer Conteúdo da Plataforma (incluindo Conteúdo do Usuário) está correto, completo ou atualizado. Quando os nossos Serviços apresentarem links para outras páginas e recursos disponibilizados por terceiros, esses links terão sido apresentados meramente para a sua informação. Não temos controle sobre o conteúdo nessas páginas ou recursos. Esses links não devem ser interpretados como uma aprovação nossa das páginas acessados pelo link, ou das informações existentes nas páginas. Você reconhece que não temos qualquer obrigação de pré-aprovar, monitorar, revisar ou editar qualquer conteúdo publicado por Você ou por outros usuários nos Serviços (inclusive Conteúdo do Usuário).
                    </Typography>

                    <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>
                        7. LEGISLAÇÃO APLICÁVEL
                    </Typography>

                    <Typography variant="body1" paragraph>
                        7.1. Estes Termos e todas as relações dele decorrentes são submetidas às leis da República Federativa do Brasil, sendo eleito, no que for aplicável, o foro da Comarca da Capital do Estado de São Paulo para dirimir qualquer controvérsia decorrente deste instrumento.
                    </Typography>

                    <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>
                        8. DISPOSIÇÕES GERAIS
                    </Typography>

                    <Typography variant="body1" paragraph>
                        8.1. Estes Termos constituem o acordo integral entre as Partes com relação ao objeto em questão, e substituem todos os acordos anteriores, escritos ou verbais.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        8.2. Fica expressamente acordado por estes Termos que este não deverá ser considerado ou interpretado, de qualquer maneira, como uma forma de investimento, "joint venture", associação, representação, comissão ou agenciamento, relação de trabalho indireta, mediação ou intermediação entre as Partes, para qualquer propósito. A relação aqui tratada é meramente contratual, entre Partes independentes, evidenciada neste Instrumento.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        8.3. Caso qualquer uma das cláusulas e condições destes Termos venha a ser declarada nula, no todo ou em parte, por qualquer motivo legal ou contratual, as demais cláusulas continuarão em pleno vigor e efeito.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        8.4. A tolerância ou o não exercício, por qualquer das Partes, de quaisquer direitos a ela assegurados nestes Termos ou na lei em geral não importará em novação ou em renúncia a qualquer desses direitos, podendo a referida Parte exercê-los a qualquer momento durante a vigência deste Instrumento.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        8.5. Nenhuma cláusula destes Termos de Uso deve afetar quaisquer direitos legalmente estabelecidos em favor do Usuário em face da Plataforma.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        8.6. Caso o Usuário tenha dúvidas, comentários ou sugestões relativas aos presentes Termos e Condições Gerais de Uso, poderá entrar em contato com a WIZARD através do nosso canal de Suporte.
                    </Typography>
                </Box>

                <FormControlLabel
                    control={
                        <Checkbox 
                            checked={accepted}
                            onChange={(e) => setAccepted(e.target.checked)}
                        />
                    }
                    label="Li e concordo com os Termos e Condições de Uso"
                />

                <ButtonFL
                    onClick={handleAccept}
                    buttonDisabled={!accepted}
                    bgcolor="var(--success-color)"
                    textColor="var(--white-color)"
                    bgColorHover="var(--success-light-color)"
                >
                    Aceitar e Continuar
                </ButtonFL>
            </Box>
        </Modal>
    );
};
