import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    Avatar,
    Box,
    Grid,
    TextField,
    Typography,
    List,
    ListItemIcon,
    ListItemText,
    ListItem,
    Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import PasswordIcon from "@mui/icons-material/Password";
import { mensagemVazio, showMessage } from "../../data/Interfaces";
import { Loader } from "../../components/ui/Loader";
import { Message } from "../../components/ui/Message";
import { Header } from "../../components/ui/Header";
import { ButtonFL } from "../../components/ui/ButtonFL";
import { BackButtonFL } from "../../components/ui/BackButtonFL";
import { GenericModal } from "../../components/ui/Modal";
import { removeSpacesFromString, alertColor } from "../../utils";
import { apiUpdatePassword, apiGetToken, apiValidateToken } from "../../services";
import { useAuthStore } from "../../store/useAuthStore";
import "./createNewPassword.css";

export const CreateNewPassword = () => {
    const creator = useAuthStore((state) => state.creator);
    const { pathname: URL } = useLocation();
    const navigator = useNavigate();

    const { t } = useTranslation("translation", { keyPrefix: "createNewPassword" });

    const [message, setMessage] = useState(mensagemVazio);
    const [token, setToken] = useState(null);
    const [messageModal, setMessageModal] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errors, setErrors] = useState([]);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [showForm, setShowForm] = useState(true);

    const validateToken = async (token) => {
        try {
            await apiValidateToken(token);
            setToken(token);
        } catch (error) {
            const message = error.includes("Token") ? "Sessão expirada" : error.message || error.toString();
            setShowForm(false);
            setMessage(message);
        } finally {
            setIsLoading(false);
        }
    };

    const getToken = async () => {
        try {
            const id = creator.id;
            const { token } = await apiGetToken(id);
            setToken(token);
        } catch (error) {
            showMessageAlert(alertColor.error, error?.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handlePasswordChange = (event) => {
        const passwordValue = removeSpacesFromString(event.target.value);
        setPassword(passwordValue);
        validatePasswords(passwordValue, confirmPassword);
    };

    const handleConfirmPasswordChange = (event) => {
        const confirmPasswordValue = removeSpacesFromString(event.target.value);
        setConfirmPassword(confirmPasswordValue);
        validatePasswords(password, confirmPasswordValue);
    };

    const validatePasswords = (passwordValue, confirmPasswordValue) => {
        const newErrors = [];

        if (passwordValue !== confirmPasswordValue) {
            newErrors.push("As senhas não coincidem.");
        }

        if (passwordValue.length < 8 && passwordValue !== "") {
            newErrors.push("A senha deve ter no mínimo 8 caracteres.");
        }

        if (!/[A-Z]/.test(passwordValue) && passwordValue !== "") {
            newErrors.push("A senha deve conter pelo menos uma letra maiúscula.");
        }

        if (!/[^A-Za-z0-9]/.test(passwordValue) && passwordValue !== "") {
            newErrors.push("A senha deve conter pelo menos um caractere especial.");
        }

        setErrors(newErrors);
        setButtonDisabled(newErrors.length > 0);
    };

    const getValidationIcon = (isValid) => {
        if (isValid) {
            return <CheckCircleIcon className="iconSuccess iconsSize" />;
        } else if (password !== "") {
            return <ErrorIcon className="iconError iconsSize" />;
        } else {
            return <FiberManualRecordIcon className="iconDefault iconsSize" />;
        }
    };

    const showMessageAlert = (alertColor, message) => {
        showMessage(
            {
                variant: alertColor,
                message,
            },
            setMessage,
        );
    };

    const handleSubmit = async (event) => {
        setIsLoading(true);
        event.preventDefault();

        try {
            const data = new FormData(event.currentTarget);
            const newPassword = removeSpacesFromString(data.get("newPassword"));
            const { message } = await apiUpdatePassword(token, newPassword);

            if (message) {
                setOpenModal(true);
                setMessageModal(message);
            }

            setIsLoading(false);
        } catch (error) {
            showMessageAlert(alertColor.error, error.message);
            setIsLoading(false);
        }
    };

    const handleGoBack = () => navigator(-1);

    const handleClick = () => navigator("/");

    const checkAndHandleToken = () => {
        const token = URL.substring(URL.lastIndexOf("/") + 1);

        if (creator) {
            setShowForm(true);
            getToken();
        }

        if (!creator && token !== "createNewPassword") {
            validateToken(token);
        }

        if (!creator && token === "createNewPassword") {
            setShowForm(false);
            setMessage("Sessão expirada");
        }
    };

    useEffect(() => {
        checkAndHandleToken();
    }, [creator]);

    return (
        <>
            {showForm ? (
                <>
                    {/* {isLoading && <Loader />} */}
                    <Header />
                    <BackButtonFL handleGoBack={handleGoBack} />
                    {message.message && <Message variant={message.variant} message={message.message} />}
                    <GenericModal open={openModal}>
                        <Box>
                            <h3 style={{ fontWeight: 200 }}>{messageModal}</h3>
                            <Button style={{ color: "var( --primary-color)", fontWeight: 900 }} onClick={handleClick}>
                                {creator ? "Ok, entendi" : "Ir para Login"}
                            </Button>
                        </Box>
                    </GenericModal>
                    <Grid
                        container
                        sx={{
                            height: "85vh",
                            backgroundColor: "var(--white-color)",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Grid item xs={10} sm={10} md={10} elevation={8}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Avatar
                                    sx={{
                                        m: 1,
                                        bgcolor: "var(--primary-color)",
                                    }}
                                >
                                    <PasswordIcon />
                                </Avatar>
                                <Typography variant="h4">{t("title")}</Typography>
                                <Box sx={{ marginTop: 3, width: "40vh", textAlign: "center", marginBottom: 1 }}>
                                    <Typography variant="body1" sx={{ color: "var(--grey-600-color)" }}>
                                        {t("description")}
                                    </Typography>
                                </Box>
                                <Box component="form" noValidate onSubmit={handleSubmit}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="newPassword"
                                        label={t("newPasswordLabel")}
                                        type="text"
                                        id="password"
                                        onChange={handlePasswordChange}
                                        value={password}
                                    />
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="confirmNewPassword"
                                        label={t("confirmPasswordLabel")}
                                        type="text"
                                        id="confirmPassword"
                                        onChange={handleConfirmPasswordChange}
                                        value={confirmPassword}
                                    />
                                    <Box sx={{ marginTop: 3 }}>
                                        <List dense>
                                            <ListItem>
                                                <ListItemIcon className="listItemIcon ">
                                                    {getValidationIcon(password === confirmPassword && password !== "")}
                                                </ListItemIcon>
                                                <ListItemText primary={t("passwordMatchError")} />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon className="listItemIcon">
                                                    {getValidationIcon(password.length >= 8)}
                                                </ListItemIcon>
                                                <ListItemText primary={t("passwordLengthError")} />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon className="listItemIcon">
                                                    {getValidationIcon(/[A-Z]/.test(password))}
                                                </ListItemIcon>
                                                <ListItemText primary={t("passwordUpperCaseError")} />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon className="listItemIcon">
                                                    {getValidationIcon(/[^A-Za-z0-9]/.test(password))}
                                                </ListItemIcon>
                                                <ListItemText primary={t("passwordSpecialCharError")} />
                                            </ListItem>
                                        </List>
                                        <ButtonFL
                                            buttonDisabled={buttonDisabled}
                                            textKey="sendNewPassword"
                                            keyPrefix="createNewPassword"
                                            type="submit"
                                        />
                                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <Button
                                                variant="text"
                                                color="error"
                                                onClick={handleGoBack}
                                                sx={{
                                                    // display: "flex",
                                                    // justifyContent: "center",
                                                    // alignItems: "center",
                                                    // backgroundColor: "var(--error-color)",
                                                    color: "var(--error-color)",
                                                    fontWeight: 900,
                                                    fontSize: 16,
                                                    // borderRadius: 1,
                                                    // padding: 1,
                                                    mt: 2,
                                                }}
                                            >
                                                Cancelar
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </>
            ) : (
                <Box sx={{ display: "flex", height: "85vh", justifyContent: "center", alignItems: "center" }}>
                    <Typography variant="h6" sx={{ color: "var(--grey-700-color)", textAlign: "center" }}>
                        {message}
                    </Typography>
                </Box>
            )}
        </>
    );
};
