import React, { useEffect, useState } from "react";
import { Box, Typography, Container } from "@mui/material";
import { ButtonFL } from "../../ui/ButtonFL";
import bgHomeDesktop from "../../../_assets/images/wizard/bg_home_desktop.png";
import bgHomeMobile from "../../../_assets/images/wizard/bg_home_mobile.png";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import { returnCampainId } from "../../../utils";
import { useMobileDetect } from "../../../hooks";

export const OmniContent = () => {
    const isMobile = useMobileDetect();
    const navigate = useNavigate();
    const [hasMounted, setHasMounted] = useState(false);

    const idCampanha = returnCampainId();
    useEffect(() => {
        const mainElement = document.querySelector("main");
        if (mainElement) {
            mainElement.classList.add("wizard-background");
        }

        setHasMounted(true);

        return () => {
            if (mainElement) {
                mainElement.classList.remove("wizard-background");
            }
        };
    }, []);

    const handleCommunityAccess = () => {
        if (idCampanha) {
            navigate(`/comunidade/${idCampanha}`);
        }
    };

    return (
        <Box
            sx={{
                position: "relative",
                minHeight: "100vh",
                width: "100%",
                overflow: "hidden",
                bgcolor: "var(--primary-color)",
                display: "flex",
                justifyContent: "center",
            }}
        >
            <Box
                sx={{
                    position: "relative",
                    width: "100%",
                    maxWidth: "1440px",
                    overflow: "hidden",
                }}
            >
                {hasMounted && (
                    <Box
                        sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            width: "100%",
                            height: { xs: "80%", md: "100%" },
                            zIndex: 0,
                            background: `url(${isMobile ? bgHomeMobile : bgHomeDesktop}) no-repeat`,
                            backgroundSize: isMobile ? "contain" : "cover",
                            backgroundPosition: isMobile ? "top center" : "right top",
                        }}
                    />
                )}

                <Container
                    sx={{
                        position: "relative",
                        zIndex: 1,
                        height: "100%",
                        display: "flex",
                        alignItems: { xs: "flex-start", md: "flex-start" },
                        pt: { xs: "25%", md: "120px" },
                        pb: { xs: 2, md: 4 },
                        px: { xs: 2, md: "16px" },
                    }}
                >
                    <Box
                        sx={{
                            width: { xs: "100%", md: "40%" },
                            display: "flex",
                            flexDirection: "column",
                            gap: 3,
                            py: { xs: 4, md: 6 },
                            px: { xs: "24px", md: 0 },
                            mt: { xs: "110px", md: 0 }
                        }}
                    >
                        <Typography
                            variant="h1"
                            sx={{
                                fontSize: { xs: "24px", md: "32px" },
                                fontWeight: "bold",
                                color: "error.main",
                            }}
                        >
                            HI WIZARDS!
                        </Typography>

                        <Typography sx={{ color: "common.white" }}>
                            Bem-vindo ao WizStars! Aqui a criatividade é o foco e as conexões são nossa força!
                        </Typography>

                        <Typography sx={{ color: "common.white" }}>
                            Prepare-se para novidades incríveis, interações e oportunidades que impulsionarão sua
                            jornada criativa.
                        </Typography>

                        <Typography sx={{ color: "common.white" }}>
                            Explore, participe e mergulhe nessa comunidade apaixonada por conteúdo.
                        </Typography>

                        <Typography sx={{ color: "common.white", mb: 2 }}>
                            Mal podemos esperar para criar juntos!
                        </Typography>

                        <ButtonFL
                            variant="contained"
                            bgcolor="var(--white-color)"
                            bgColorHover="var(--white-color)"
                            textColor="var(--primary-color)"
                            onClick={handleCommunityAccess}
                            sx={{
                                mt: 2,
                                maxWidth: "300px",
                                alignSelf: { xs: "center", md: "flex-start" },
                                borderRadius: "0",
                                height: "48px",
                            }}
                        >
                            Acessar a comunidade
                        </ButtonFL>
                    </Box>
                </Container>
            </Box>
        </Box>
    );
};
