import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';

export const NoAccess = () => {
    return (
        <Container>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '60vh',
                    textAlign: 'center',
                    gap: 2
                }}
            >
                <BlockIcon sx={{ fontSize: 64, color: 'var(--error-color)' }} />
                <Typography variant="h4" component="h1" gutterBottom>
                    Acesso Restrito
                </Typography>
                <Typography variant="body1" color="text.secondary">
                    Você não tem permissão para acessar esta página.
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Entre em contato com o administrador para solicitar acesso.
                </Typography>
            </Box>
        </Container>
    );
};
