import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Paper,
    BottomNavigation,
    BottomNavigationAction,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemButton,
} from "@mui/material";
import { Settings } from "@mui/icons-material";
import { useMobileDetect } from "../../hooks";
import logoWizard from "../../_assets/logo_wizard.png";
import logoDefault from "../../_assets/logo.png";
import { ProfileMenu } from "../ui/ProfileMenu";
import { useAuthStore } from "../../store/useAuthStore";
import { buildMenuItems } from "../../data/MenusItens";

const logos = {
    wizard: logoWizard,
    default: logoDefault,
};

export const Menu = ({ client = "default", creatorIsAdmin }) => {
    const navigate = useNavigate();
    const isMobile = useMobileDetect();
    const [selected, setSelected] = React.useState(0);
    const [openProfileMenu, setOpenProfileMenu] = useState(false);
    const creator = useAuthStore((state) => state.creator);

    const getLogo = (client) => logos[client] || logos.default;

    const handleNavigation = (index, item) => {
        setSelected(index);
        if (item.link) {
            navigate(item.link);
        }
    };

    const toggleProfileMenu = () => {
        setOpenProfileMenu(!openProfileMenu);
    };

    const filteredMenuItems = buildMenuItems({ creatorIsAdmin, isMobile });

    if (isMobile) {
        return (
            <Paper
                sx={{
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: 1000,
                    backgroundColor: "var(--primary-dark-color)",
                }}
                elevation={3}
            >
                <BottomNavigation
                    showLabels
                    value={selected}
                    onChange={(event, newValue) => {
                        setSelected(newValue);
                    }}
                    sx={{
                        backgroundColor: "var(--primary-dark-color)",
                        "& .MuiBottomNavigationAction-root": {
                            color: "var(--white-color)",
                            "&.Mui-selected": {
                                color: "var(--white-color)",
                            },
                            "& .MuiSvgIcon-root": {
                                color: "var(--white-color)",
                            },
                        },
                    }}
                >
                    {filteredMenuItems.map((item, index) => {
                        if (item.showMobile === false) return null;
                        const Icon = selected === index ? item?.activeIcon : item?.icon;
                        return (
                            <BottomNavigationAction
                                key={index}
                                label={item.title}
                                icon={<Icon />}
                                onClick={() => handleNavigation(index, item)}
                            />
                        );
                    })}
                    <BottomNavigationAction
                        key="settings"
                        label="Configurações"
                        icon={<Settings />}
                        onClick={toggleProfileMenu}
                        sx={{
                            marginLeft: "auto",
                            color: "var(--white-color) !important",
                            "& .MuiSvgIcon-root": {
                                color: "var(--white-color) !important",
                            },
                        }}
                    />
                </BottomNavigation>
                <ProfileMenu openDrawer={openProfileMenu} toggleDrawer={toggleProfileMenu} creator={creator} />
            </Paper>
        );
    }

    return (
        <Box
            sx={{
                width: 240,
                height: "100vh",
                backgroundColor: "var(--primary-dark-color)",
                color: "var(--white-color)",
                display: "flex",
                flexDirection: "column",
                position: "fixed",
                left: 0,
                top: 0,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "16px",
                }}
            >
                <img
                    src={getLogo(client)}
                    alt={`${client} logo`}
                    style={{
                        maxWidth: "100%",
                        maxHeight: "80px",
                        objectFit: "contain",
                    }}
                />
            </Box>

            <List>
                {filteredMenuItems.map((item, index) => (
                    <ListItem
                        button
                        key={index}
                        selected={selected === index}
                        onClick={() => handleNavigation(index, item)}
                        sx={{
                            "&.Mui-selected": {
                                backgroundColor: "var(--secondary-color)",
                            },
                        }}
                    >
                        <ListItemIcon sx={{ color: "inherit" }}>
                            <item.icon />
                        </ListItemIcon>
                        <ListItemText primary={item.title} />
                    </ListItem>
                ))}
            </List>

            <Box sx={{ mt: "auto" }}>
                <ListItem
                    onClick={toggleProfileMenu}
                    sx={{
                        borderTop: "1px solid rgba(255, 255, 255, 0.12)",
                    }}
                >
                    <ListItemIcon sx={{ color: "inherit" }}>
                        <Settings />
                    </ListItemIcon>
                    <ListItemText primary="Configurações" />
                </ListItem>
            </Box>

            <ProfileMenu openDrawer={openProfileMenu} toggleDrawer={toggleProfileMenu} creator={creator} />
        </Box>
    );
};
