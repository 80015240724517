import React, { useMemo, useState, useEffect } from "react";
import {
    Card,
    CardContent,
    CardHeader,
    CardMedia,
    Avatar,
    IconButton,
    Typography,
    Grid,
    Fab,
    Chip,
    Collapse,
    Box,
} from "@mui/material";
import {
    Favorite,
    ChatBubbleOutline,
    Add as AddIcon,
    FavoriteBorder,
    KeyboardArrowDown,
    KeyboardArrowUp,
    FilterList,
} from "@mui/icons-material";

import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useNavigate, useLocation } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { usePosts } from "../../hooks";
import { capitalizerFirstLetter } from "../../utils";
import { Loader } from "../../components/ui/Loader";
import { apiPostLike, apiDeleteLike } from "../../services";
import { useAuthStore } from "../../store/useAuthStore";
import { useErrorStore } from "../../store/useErrorStore";
import { LinkCard } from "./linkCard";
import { CATEGORY_DESAFIO } from "../../utils/constants";

export const Post = ({ community }) => {
    const navigate = useNavigate();
    const creator = useAuthStore((state) => state.creator);
    const showError = useErrorStore((state) => state.showError);
    const location = useLocation();
    const activeFilter = location.state?.activeFilter;

    // Filter states
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [showMyPosts, setShowMyPosts] = useState(false);
    const [showILikedPosts, setShowILikedPosts] = useState(false);
    const [mediaType, setMediaType] = useState("");

    const {
        data: postsData,
        fetchNextPage,
        hasNextPage,
        isLoading,
        error,
    } = usePosts(community?.id, {
        author: showMyPosts ? creator.id : null,
        iLiked: showILikedPosts ? creator.id : null,
        categories: selectedCategories.length > 0 ? selectedCategories : null,
        type: mediaType || null,
    });

    const posts = useMemo(() => postsData?.pages?.flatMap((page) => page) ?? [], [postsData]);
    const pinnedPosts = posts.filter((post) => post.pinado === true);
    const [likedPosts, setLikedPosts] = useState({});

    useEffect(() => {
        const initialLikedPosts = {};
        posts.forEach((post) => {
            const isLikedByUser = post?.engajamento?.likes.some((like) => like?.creator === creator.id);
            initialLikedPosts[post.id] = {
                isLiked: isLikedByUser,
                likesCount: post.engajamento?.likes?.length,
            };
        });
        setLikedPosts(initialLikedPosts);
    }, [posts, creator.id]);

    const handleLike = async (postId) => {
        const isCurrentlyLiked = likedPosts[postId]?.isLiked;

        try {
            if (isCurrentlyLiked) {
                await apiDeleteLike({ idPost: postId, creator: creator.id });
                setLikedPosts((prev) => ({
                    ...prev,
                    [postId]: {
                        isLiked: false,
                        likesCount: prev[postId].likesCount - 1,
                    },
                }));
            } else {
                await apiPostLike({ idPost: postId, creator: creator.id });
                setLikedPosts((prev) => ({
                    ...prev,
                    [postId]: {
                        isLiked: true,
                        likesCount: prev[postId].likesCount + 1,
                    },
                }));
            }
        } catch (error) {
            showError(error);
        }
    };

    const handlePostClick = (post) => {
        navigate(`/post/${post.id}`);
    };

    const handleCategoryChange = (category) => {
        setSelectedCategories((prev) =>
            prev.includes(category) ? prev.filter((c) => c !== category) : [...prev, category],
        );
    };

    const handleMediaTypeChange = (event, newType) => {
        // Se clicar no mesmo tipo já selecionado, desseleciona
        setMediaType(mediaType === newType ? null : newType);
    };

    const handleToggleFilters = () => {
        setFiltersOpen(!filtersOpen);
    };

    // Adicionar essa função para verificar se há filtros ativos
    const hasActiveFilters = useMemo(() => {
        return selectedCategories.length > 0 || showMyPosts || showILikedPosts || mediaType !== "";
    }, [selectedCategories, showMyPosts, showILikedPosts, mediaType]);

    if (error) return <p>Erro ao carregar posts.</p>;

    useEffect(() => {
        setShowMyPosts(activeFilter === "meus-posts");
        setShowILikedPosts(activeFilter === "curtidas");
    }, [activeFilter]);

    return (
        <div style={{ position: "relative", minHeight: "100vh" }}>
            {/* Filters Section */}
            <Box sx={{ width: "100%" }}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        mb: 1,
                        pb: 0,
                        borderBottom: "1px solid var(--primary-color)",
                    }}
                >
                    <Typography
                        variant="h6"
                        sx={{
                            fontWeight: 500,
                            color: "var(--primary-color)",
                        }}
                    >
                        Posts da Comunidade
                    </Typography>

                    <IconButton
                        onClick={handleToggleFilters}
                        sx={{
                            color: hasActiveFilters ? "var(--primary-color)" : "inherit",
                            transform: filtersOpen ? "rotate(180deg)" : "rotate(0deg)",
                            transition: "all 0.3s ease",
                            ...(hasActiveFilters && {
                                background: "rgba(255, 255, 255, 0.1)",
                                backdropFilter: "blur(10px)",
                                WebkitBackdropFilter: "blur(10px)",
                                border: "1px solid rgba(255, 255, 255, 0.18)",
                                boxShadow: "0 4px 12px 0 rgba(31, 38, 135, 0.15)",
                                "&:hover": {
                                    background: "rgba(255, 255, 255, 0.15)",
                                },
                            }),
                        }}
                    >
                        <FilterList />
                    </IconButton>
                </Box>

                <Collapse in={filtersOpen} sx={{ width: "100%" }}>
                    <Box
                        sx={{
                            p: 2.5,
                            background: "rgba(255, 255, 255, 0.15)",
                            backdropFilter: "blur(10px)",
                            WebkitBackdropFilter: "blur(10px)",
                            borderRadius: 2,
                            border: "1px solid rgba(255, 255, 255, 0.18)",
                            boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.15)",
                            position: "relative",
                            "&:before": {
                                content: '""',
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                background:
                                    "linear-gradient(128deg, rgba(255,255,255,0.12) 0%, rgba(255,255,255,0.06) 100%)",
                                borderRadius: 2,
                                zIndex: -1,
                            },
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                mb: 2,
                                fontWeight: 500,
                                color: "var(--primary-color)",
                            }}
                        >
                            Filtrar Posts
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            sx={{
                                mb: 1,
                                color: "var(--primary-dark-color)",
                                fontWeight: 500,
                            }}
                        >
                            Editoria:
                        </Typography>
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 2 }}>
                            {[CATEGORY_DESAFIO, ...(community?.categorias || [])]?.map((category) => (
                                <Chip
                                    key={category}
                                    label={category}
                                    onClick={() => handleCategoryChange(category)}
                                    sx={{
                                        color: selectedCategories.includes(category)
                                            ? "var(--white-color)"
                                            : "var(--newpost-cattegorychiptext-color)",
                                        bgcolor: selectedCategories.includes(category)
                                            ? "var(--newpost-cattegorychiptextselected-color)"
                                            : "var(--newpost-cattegorychip-color)",
                                        opacity: selectedCategories.includes(category) ? 1 : 0.6,
                                        "&:hover": {
                                            bgcolor: selectedCategories.includes(category)
                                                ? "var(--newpost-cattegorychiptextselected-color)"
                                                : "var(--newpost-cattegorychip-color)",
                                            opacity: selectedCategories.includes(category) ? 1 : 0.8,
                                        },
                                        "&:active": {
                                            bgcolor: selectedCategories.includes(category)
                                                ? "var(--newpost-cattegorychiptextselected-color)"
                                                : "var(--newpost-cattegorychip-color)",
                                            opacity: selectedCategories.includes(category) ? 1 : 0.8,
                                        },
                                    }}
                                />
                            ))}
                        </Box>

                        <Typography
                            variant="subtitle2"
                            sx={{
                                mb: 1,
                                color: "var(--primary-dark-color)",
                                fontWeight: 500,
                            }}
                        >
                            Autor:
                        </Typography>
                        <Box sx={{ mb: 2 }}>
                            <Chip
                                label="Meus Posts"
                                onClick={() => setShowMyPosts(!showMyPosts)}
                                sx={{
                                    color: showMyPosts
                                        ? "var(--white-color)"
                                        : "var(--newpost-cattegorychiptext-color)",
                                    bgcolor: showMyPosts
                                        ? "var(--newpost-cattegorychiptextselected-color)"
                                        : "var(--newpost-cattegorychip-color)",
                                    opacity: showMyPosts ? 1 : 0.6,
                                    "&:hover": {
                                        bgcolor: showMyPosts
                                            ? "var(--newpost-cattegorychiptextselected-color)"
                                            : "var(--newpost-cattegorychip-color)",
                                        opacity: showMyPosts ? 1 : 0.8,
                                    },
                                    "&:active": {
                                        bgcolor: showMyPosts
                                            ? "var(--newpost-cattegorychiptextselected-color)"
                                            : "var(--newpost-cattegorychip-color)",
                                        opacity: showMyPosts ? 1 : 0.8,
                                    },
                                }}
                            />
                        </Box>

                        <Typography
                            variant="subtitle2"
                            sx={{
                                mb: 1,
                                color: "var(--primary-dark-color)",
                                fontWeight: 500,
                            }}
                        >
                            Curtida:
                        </Typography>
                        <Box sx={{ mb: 2 }}>
                            <Chip
                                label="Posts que eu curti!"
                                onClick={() => setShowILikedPosts(!showILikedPosts)}
                                sx={{
                                    color: showILikedPosts
                                        ? "var(--white-color)"
                                        : "var(--newpost-cattegorychiptext-color)",
                                    bgcolor: showILikedPosts
                                        ? "var(--newpost-cattegorychiptextselected-color)"
                                        : "var(--newpost-cattegorychip-color)",
                                    opacity: showILikedPosts ? 1 : 0.6,
                                    "&:hover": {
                                        bgcolor: showILikedPosts
                                            ? "var(--newpost-cattegorychiptextselected-color)"
                                            : "var(--newpost-cattegorychip-color)",
                                        opacity: showILikedPosts ? 1 : 0.8,
                                    },
                                    "&:active": {
                                        bgcolor: showILikedPosts
                                            ? "var(--newpost-cattegorychiptextselected-color)"
                                            : "var(--newpost-cattegorychip-color)",
                                        opacity: showILikedPosts ? 1 : 0.8,
                                    },
                                }}
                            />
                        </Box>

                        <Typography
                            variant="subtitle2"
                            sx={{
                                mb: 1,
                                color: "var(--primary-dark-color)",
                                fontWeight: 500,
                            }}
                        >
                            Exibir posts contendo:
                        </Typography>
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 2 }}>
                            {["Vídeo", "Texto", "Foto", "Link"].map((type) => (
                                <Chip
                                    key={type}
                                    label={type}
                                    onClick={() => handleMediaTypeChange(null, type.toLowerCase())}
                                    sx={{
                                        color:
                                            mediaType === type.toLowerCase()
                                                ? "var(--white-color)"
                                                : "var(--newpost-cattegorychiptext-color)",
                                        bgcolor:
                                            mediaType === type.toLowerCase()
                                                ? "var(--newpost-cattegorychiptextselected-color)"
                                                : "var(--newpost-cattegorychip-color)",
                                        opacity: mediaType === type.toLowerCase() ? 1 : 0.6,
                                        "&:hover": {
                                            bgcolor:
                                                mediaType === type.toLowerCase()
                                                    ? "var(--newpost-cattegorychiptextselected-color)"
                                                    : "var(--newpost-cattegorychip-color)",
                                            opacity: mediaType === type.toLowerCase() ? 1 : 0.8,
                                        },
                                        "&:active": {
                                            bgcolor:
                                                mediaType === type.toLowerCase()
                                                    ? "var(--newpost-cattegorychiptextselected-color)"
                                                    : "var(--newpost-cattegorychip-color)",
                                            opacity: mediaType === type.toLowerCase() ? 1 : 0.8,
                                        },
                                    }}
                                />
                            ))}
                        </Box>
                    </Box>
                </Collapse>
            </Box>

            {isLoading && <Loader />}
            <InfiniteScroll
                dataLength={posts.length - pinnedPosts.length}
                next={fetchNextPage}
                hasMore={hasNextPage}
                loader={<p>Carregando mais...</p>}
                endMessage={
                    <p style={{ textAlign: "center" }}>
                        <b>Você chegou ao final!</b>
                    </p>
                }
            >
                <Grid container spacing={2} marginTop={0} marginBottom={10}>
                    {pinnedPosts.length > 0 && (
                        <>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontWeight: "bold",
                                        color: "var(--primary-dark-color)",
                                        marginBottom: 2,
                                    }}
                                >
                                    Post Fixado
                                </Typography>
                            </Grid>

                            {pinnedPosts.map((post) => (
                                <Grid item xs={12} key={post.id}>
                                    <Card
                                        sx={{
                                            maxWidth: {
                                                xs: 500,
                                                md: 500,
                                            },
                                            margin: "auto",
                                            borderRadius: "16px",
                                            overflow: "hidden",
                                            backgroundColor: "var(--grey-200-color)",
                                        }}
                                        onClick={() => handlePostClick(post)}
                                    >
                                        <CardHeader
                                            avatar={
                                                <Avatar className="creatorAvatar">
                                                    {post.creator?.nome?.charAt(0) ?? "A"}
                                                </Avatar>
                                            }
                                            title={
                                                <Typography variant="subtitle1" fontWeight="bold">
                                                    {capitalizerFirstLetter(post.creator?.nome ?? "Anônimo")}
                                                </Typography>
                                            }
                                            subheader={
                                                post?.createdAt
                                                    ? new Date(post?.createdAt).toLocaleString()
                                                    : "Data indisponível"
                                            }
                                        />

                                        {post?.media?.link && (
                                            <Box sx={{ width: "100%", boxSizing: "border-box", px: 2, mb: 2 }}>
                                                <LinkCard
                                                    title="Compartilhou uma publicação externa:"
                                                    link={post?.media?.link}
                                                />
                                            </Box>
                                        )}

                                        {(post?.media?.videoCapa ?? post?.media?.imageUrl) && (
                                            <div
                                                style={{
                                                    position: "relative",
                                                    width: "100%",
                                                    height: "400px",
                                                    borderRadius: "16px",
                                                    overflow: "hidden",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <CardMedia
                                                    component="img"
                                                    src={post?.media?.videoCapa ?? post?.media?.imageUrl}
                                                    style={{
                                                        width: "90%",
                                                        height: "100%",
                                                        objectFit: "cover",
                                                        borderRadius: "16px",
                                                    }}
                                                />
                                                {post?.media?.videoUrl && (
                                                    <PlayArrowIcon
                                                        sx={{
                                                            position: "absolute",
                                                            top: "50%",
                                                            left: "50%",
                                                            transform: "translate(-50%, -50%)",
                                                            color: "white",
                                                            fontSize: 60,
                                                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                                                            borderRadius: "50%",
                                                            padding: "8px",
                                                            pointerEvents: "none",
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        )}

                                        <CardContent>
                                            <Typography variant="body2" sx={{ fontWeight: 500 }}>
                                                {post.texto ? capitalizerFirstLetter(post.texto) : ""}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </>
                    )}

                    {posts
                        .filter((post) => !post.pinado)
                        .map((post) => (
                            <Grid item xs={12} key={post.id}>
                                <Card
                                    sx={{
                                        maxWidth: {
                                            xs: 500,
                                            md: 500,
                                        },
                                        margin: "auto",
                                        borderRadius: "16px",
                                        overflow: "hidden",
                                    }}
                                    onClick={() => handlePostClick(post)}
                                >
                                    <CardHeader
                                        avatar={
                                            <Avatar className="creatorAvatar">
                                                {post.creator?.nome?.charAt(0) ?? "A"}
                                            </Avatar>
                                        }
                                        title={
                                            <Typography variant="subtitle1" fontWeight="bold">
                                                {capitalizerFirstLetter(post.creator?.nome ?? "Anônimo")}
                                            </Typography>
                                        }
                                        subheader={
                                            post?.createdAt
                                                ? new Date(post?.createdAt).toLocaleString()
                                                : "Data indisponível"
                                        }
                                    />

                                    {post?.media?.link && (
                                        <Box sx={{ width: "100%", boxSizing: "border-box", px: 2, mb: 2 }}>
                                            <LinkCard
                                                title="Compartilhou uma publicação externa:"
                                                link={post?.media?.link}
                                            />
                                        </Box>
                                    )}

                                    {(post?.media?.videoCapa ?? post?.media?.imageUrl) && (
                                        <div
                                            style={{
                                                position: "relative",
                                                width: "100%",
                                                height: "400px",
                                                borderRadius: "16px",
                                                overflow: "hidden",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <CardMedia
                                                component="img"
                                                src={post?.media?.videoCapa ?? post?.media?.imageUrl}
                                                style={{
                                                    width: "90%",
                                                    height: "100%",
                                                    objectFit: "cover",
                                                    borderRadius: "16px",
                                                }}
                                            />
                                            {post?.media?.videoUrl && (
                                                <PlayArrowIcon
                                                    sx={{
                                                        position: "absolute",
                                                        top: "50%",
                                                        left: "50%",
                                                        transform: "translate(-50%, -50%)",
                                                        color: "white",
                                                        fontSize: 60,
                                                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                                                        borderRadius: "50%",
                                                        padding: "8px",
                                                        pointerEvents: "none",
                                                    }}
                                                />
                                            )}
                                        </div>
                                    )}

                                    <CardContent>
                                        <Typography variant="body2">
                                            {post.texto ? capitalizerFirstLetter(post.texto) : ""}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                </Grid>
            </InfiniteScroll>

            {community?.id > 0 && (
                <Fab
                    color="primary"
                    aria-label="postar"
                    style={{
                        position: "fixed",
                        bottom: 80,
                        right: 24,
                        backgroundColor: "var(--primary-color)",
                        color: "var(--white-color)",
                        zIndex: 1000,
                    }}
                    onClick={() => navigate(`/novoPost/${community.id}`, { state: { community } })}
                >
                    <AddIcon />
                </Fab>
            )}
        </div>
    );
};
