import React from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

export const ManageNavigation = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const pathParts = location.pathname.split('/');
    const communityId = pathParts[pathParts.indexOf('comunidade') + 1];
    const currentPath = pathParts[pathParts.length - 1];

    const handleChange = (event, newValue) => {
        navigate(`/comunidade/${communityId}/gerenciar/${newValue}`);
    };

    const getTabValue = () => {
        switch (currentPath) {
            case 'posts':
                return 'posts';
            case 'moderacao':
                return 'moderacao';
            case 'membros':
                return 'membros';
            default:
                return 'posts';
        }
    };

    return (
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
            <Tabs 
                value={getTabValue()} 
                onChange={handleChange}
                sx={{
                    '& .MuiTab-root': {
                        color: 'rgba(0, 0, 0, 0.87)', 
                        '&.Mui-selected': {
                            color: 'var(--primary-color)',
                        }
                    },
                    '& .MuiTabs-indicator': {
                        backgroundColor: 'var(--primary-color)',
                    }
                }}
            >
                <Tab 
                    label="Relatório de Posts" 
                    value="posts"
                />
                <Tab 
                    label="Moderação de Conteúdos" 
                    value="moderacao"
                />
                <Tab 
                    label="Relatório de Membros" 
                    value="membros"
                />
            </Tabs>
        </Box>
    );
};
