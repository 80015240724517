import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, Alert } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { mensagemVazio, showMessage } from "../data/Interfaces";
import { alertColor } from "../utils";

export const AuthorizationModal = ({ open, onClose, getAuthorizationStatus, updateComponentState, onSignDocument }) => {
    const [message, setMessage] = useState(mensagemVazio);

    const handleGetAuthorizationStatus = async (notification) => {
        try {
            await getAuthorizationStatus(notification);

            showMessage(
                {
                    variant: alertColor.success,
                    message: "Autorização reenviada com sucesso!",
                },
                setMessage,
            );
        } catch (error) {
            console.error("Erro ao atualizar autorização:", error);
        }
    };

    const handleUpdateComponentState = async () => {
        try {
            const response = await getAuthorizationStatus();
            if (response?.status !== "close") {
                showMessage(
                    {
                        variant: alertColor.error,
                        message: (
                            <>
                                O documento ainda não foi assinado.
                                <br />
                                Por favor, assine o documento para continuar.
                            </>
                        ),
                    },
                    setMessage,
                );
            } else {
                updateComponentState();
            }
        } catch (error) {
            console.error("Erro ao verificar status:", error);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                    onClose(event, reason);
                }
            }}
            maxWidth="xs"
            fullWidth
        >
            <Box sx={{ p: 3 }}>
                <DialogTitle sx={{ p: 0, mb: 3, textAlign: "center", fontWeight: 600, fontSize: "18px" }}>
                    Autorização Necessária
                </DialogTitle>

                <DialogContent
                    sx={{ p: 3, backgroundColor: "#F6EDFF", borderRadius: "8px", border: "1px solid #D1B3FF" }}
                >
                    <Box sx={{ my: 3 }}>
                        <Typography
                            variant="body2"
                            sx={{
                                fontSize: "14px",
                                fontWeight: 500,
                                color: "#6E4D9F",
                                lineHeight: "1.5",
                            }}
                        >
                            Antes de publicar na comunidade, é necessário assinar o termo de direitos de imagem. <br />
                            <br />
                            Clique no botão abaixo para visualizar e assinar o documento agora.
                        </Typography>
                    </Box>

                    <Box sx={{ display: "flex", gap: 1 }}>
                        <MailOutlineIcon
                            sx={{
                                fontSize: "18px",
                                color: "#A355FF",
                                marginTop: "2px",
                            }}
                        />
                        <Typography
                            variant="body2"
                            sx={{
                                fontSize: "14px",
                                fontWeight: 500,
                                color: "#6E4D9F",
                                lineHeight: "1.5",
                            }}
                        >
                            Uma cópia também foi enviada para o seu e-mail cadastrado, caso prefira revisá-lo mais
                            tarde.
                        </Typography>
                    </Box>
                </DialogContent>

                {message.message && (
                    <Box sx={{ width: "100%", mt: 3 }}>
                        <Alert severity={message.variant} onClose={() => setMessage(mensagemVazio)}>
                            {message.message}
                        </Alert>
                    </Box>
                )}

                <DialogActions sx={{ p: 0, mt: 3, display: "flex", flexDirection: "column", gap: 2 }}>
                    <Button
                        variant="contained"
                        onClick={onSignDocument}
                        fullWidth
                        sx={{
                            textTransform: "none",
                            fontSize: "14px",
                            borderRadius: "8px",
                            padding: "10px 16px",
                            backgroundColor: "var(--success-color)",
                            "&:hover": {
                                backgroundColor: "var(--success-color)",
                                opacity: 0.9,
                            },
                        }}
                    >
                        Assinar Documento
                    </Button>
                </DialogActions>

                <DialogContent
                    sx={{
                        mt: 3,
                        p: "12px 16px",
                        display: "flex",
                        alignItems: "flex-start",
                        gap: 1,
                        backgroundColor: "#F5F5F5",
                        borderRadius: "8px",
                    }}
                >
                    <InfoOutlinedIcon sx={{ color: "#757575", fontSize: 20 }} />
                    <Typography variant="body2" sx={{ fontSize: "13px", color: "#555", lineHeight: 1.4 }}>
                        Se você já assinou, clique em "Já Assinei" para atualizar seu status e continuar.
                    </Typography>
                </DialogContent>

                <DialogActions sx={{ p: 0, mt: 3 }}>
                    <Button
                        variant="contained"
                        onClick={handleUpdateComponentState}
                        fullWidth
                        sx={{
                            textTransform: "none",
                            fontSize: "14px",
                            borderRadius: "8px",
                            padding: "10px 16px",
                            backgroundColor: "#8a2be2",
                            "&:hover": {
                                backgroundColor: "#8a2be2",
                                opacity: 0.9,
                            },
                        }}
                    >
                        Já Assinei
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    );
};
